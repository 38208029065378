import React from 'react'
import Image from 'next/image'
import CreditIcon from '../../../public/static/credit_icon.svg'
import { useCreditAmount } from '../../../hooks/currencies/useCurrencies'

type Props = {
  theme?: 'light' | 'dark'
}

export default function CurrentBalanceSection({ theme = 'dark' }: Props) {
  const { yourCreditString } = useCreditAmount()

  return (
    <div className={'flex flex-row'}>
      <div
        onClick={(e) => {
          e.preventDefault()
        }}
        className='flex flex-row items-center gap-2'
      >
        <div className={'relative h-6 w-6'}>
          <Image
            src={CreditIcon}
            fill={true}
            className={'rounded-full object-cover'}
            alt={'Credit icon'}
          />
        </div>

        <p
          className={`text-body-medium ${
            theme === 'light' ? ' text-fizz-1000 ' : ' text-white '
          }`}
        >
          TapIn Credits: {yourCreditString}
        </p>
      </div>
    </div>
  )
}
