import { ProductResponse } from '../../types/products/product-response'
import { sendGTMEvent } from '../../lib/customGTM'

export function captureAddedPaymentMethodEvent(
  email?: string,
  userId?: number,
) {
  try {
    if (process.env.NODE_ENV !== 'production') return

    sendGTMEvent({
      event: 'AddPaymentInfo',
      email: email ?? '',
      externalId: userId?.toString() ?? '',
    })
  } catch (e) {}
}

export function captureSignupEvent() {
  try {
    if (process.env.NODE_ENV !== 'production') return

    sendGTMEvent({
      event: 'CompleteRegistration',
    })
  } catch (e) {}
}

export function captureInitiateCheckout(email?: string, userId?: number) {
  try {
    if (process.env.NODE_ENV !== 'production') return

    sendGTMEvent({
      event: 'InitiateCheckout',
      email: email ?? '',
      externalId: userId?.toString() ?? '',
    })
  } catch (e) {}
}

export function captureAddToCart(email?: string, userId?: number) {
  try {
    if (process.env.NODE_ENV !== 'production') return

    sendGTMEvent({
      event: 'AddToCart',
      email: email ?? '',
      externalId: userId?.toString() ?? '',
    })
  } catch (e) {}
}

export function captureViewProductFull(
  product: ProductResponse,
  email?: string,
  userId?: number,
) {
  try {
    if (process.env.NODE_ENV !== 'production') return

    sendGTMEvent({
      event: 'ViewContent',
      content_id: product.id,
      description: product.about,
      content_type: 'product',
      email: email ?? '',
      externalId: userId?.toString() ?? '',
    })
  } catch (e) {}
}
