import React from 'react'
import Image from 'next/image'
import { creditCardImage } from '../../common/utils/payment.utils'
import { PaymentResponse } from '../../types/stripe/responses/stripe-payment-response'
import { PaymentCustomerTypeEnum } from '../../common/enums/payment-customer-type.enum'

type Props = {
  payment: PaymentResponse
  theme?: 'light' | 'dark'
}

export default function DefaultCreditCard({ payment, theme }: Props) {
  return (
    <div className={'flex flex-row items-center justify-between'}>
      <div className={'flex flex-row items-center'}>
        <div className={'relative h-6 w-6 rounded-full bg-white'}>
          <Image
            src={
              payment.type === PaymentCustomerTypeEnum.BRAINTREE
                ? payment.braintreePayment?.imageUrl ??
                  process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
                : creditCardImage(payment.stripePayment)
            }
            className={'rounded-full object-cover'}
            fill={true}
            alt={'Credit card icon'}
          />
        </div>

        <p
          className={`text-body-medium ${
            theme === 'light' ? ' text-fizz-1000 ' : ' text-white '
          }  ml-2 capitalize`}
        >
          {payment.stripePayment?.cashapp
            ? 'Cash App'
            : payment.type === PaymentCustomerTypeEnum.BRAINTREE
              ? payment.braintreePayment?.last4
                ? `${payment.braintreePayment?.cardType} ${payment.braintreePayment?.last4}`
                : payment.braintreePayment?.venmoUserId
                  ? 'Venmo'
                  : 'PayPal'
              : `${payment.stripePayment?.card?.brand} ${payment.stripePayment?.card?.last4}`}
        </p>
      </div>
    </div>
  )
}
