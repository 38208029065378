import React from 'react'
import Image from 'next/image'
import { PaymentCustomerTypeEnum } from '../../../common/enums/payment-customer-type.enum'
import { PaymentResponse } from '../../../types/stripe/responses/stripe-payment-response'
import { creditCardImage } from '../../../common/utils/payment.utils'
import { useCreditAmount } from '../../../hooks/currencies/useCurrencies'

type Props = {
  theme?: 'light' | 'dark'
  payment: PaymentResponse
}

export default function BalanceAndPayment({ payment, theme }: Props) {
  const { yourCreditString } = useCreditAmount()

  return (
    <div className={'flex flex-row items-center gap-1'}>
      <div className={'flex flex-row items-center justify-between'}>
        <div className={'flex flex-row items-center gap-2'}>
          <div className={'relative h-6 w-6 shrink-0 rounded-full bg-white'}>
            <Image
              src={
                payment.type === PaymentCustomerTypeEnum.BRAINTREE
                  ? payment.braintreePayment?.imageUrl ??
                    process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!
                  : creditCardImage(payment.stripePayment)
              }
              className={'rounded-full object-cover'}
              fill={true}
              alt={'Credit card icon'}
            />
          </div>

          <p
            className={`text-body-medium ${
              theme === 'light' ? ' text-fizz-1000 ' : ' text-white '
            }\`  truncate capitalize`}
          >
            {payment.stripePayment?.cashapp
              ? 'Cash App'
              : payment.type === PaymentCustomerTypeEnum.BRAINTREE
                ? payment.braintreePayment?.last4
                  ? `${payment.braintreePayment?.cardType} ${payment.braintreePayment?.last4}`
                  : payment.braintreePayment?.venmoUserId
                    ? 'Venmo'
                    : 'PayPal'
                : `${payment.stripePayment?.card?.brand} ${payment.stripePayment?.card?.last4}`}
            {/*: $*/}
            {/*{(total - (user?.creditAccount?.creditUnitAmount ?? 0)).toFixed(*/}
            {/*  2*/}
            {/*) ?? 0}*/}
          </p>
        </div>
      </div>

      <p
        className={`text-secondary-normal w-fit ${
          theme === 'light' ? ' text-fizz-1000 ' : ' text-white '
        } `}
      >
        &
      </p>

      <div
        onClick={(e) => {
          e.preventDefault()
        }}
        className='flex flex-row items-center gap-2'
      >
        <div className={'relative hidden h-6 w-6 shrink-0'}>
          <Image
            src={process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!}
            fill={true}
            className={'rounded-full object-cover'}
            alt={'Wallet icon'}
          />
        </div>

        <p
          className={`text-body-medium truncate ${
            theme === 'light' ? ' text-fizz-1000 ' : ' text-white '
          }`}
        >
          TapIn Credits: {yourCreditString}
        </p>
      </div>
    </div>
  )
}
